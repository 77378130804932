.homeTitle {
  font-size: 5.4rem;
  margin: 2rem 0 3rem;
  color: black;
  text-transform: capitalize;
  font-weight: 300;
}

.homeTitleH2 {
  margin-top: 1rem;
  font-size: 2rem;
  color: rgb(171, 171, 171);
  font-weight: 400;
  line-height: 2.4rem;
}

.homeBodyText {
  margin: 2rem 0 1rem;
  font-size: 1.8rem;
  color: black;
  line-height: 2.8rem;
}

.img {
  width: 55vw;
  height: auto;
}

.pageDivFoto {
  position: relative;
  top: 2.5rem;
  animation: animPhoto 0.8s ease-in;

}

@keyframes animPhoto {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
   opacity: 1;
  }
}

.secMain {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
}

.pageDivText {
  padding: 0 4rem;
}

.pageDivText1 {
  padding-right: 8rem;
}

.meerInfo {
  position: relative;
  text-decoration: none;
  padding: 1.2rem 3.6rem;
  font-weight: 400;
  margin-top: 2rem;
  font-size: 1.8rem;
  color: white;
  display: inline-block;
  background: linear-gradient(45deg, #2062fd, #6c62f6);
  cursor: pointer;
  outline: none;
  box-shadow: 0 1rem 2rem rgb(129, 149, 211, 0.4);
}

.meerInfo:hover {
  background: rgb(255, 255, 255, 0.2);
  color: #6c62f6;
  font-weight: 600;
  transition: color 1s;
}

.meerInfo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border: .2rem solid transparent;
}

.meerInfo:hover::before {
  animation: animate 0.5s linear forwards;
}

@keyframes animate {
  0% {
    width: 0;
    height: 0;
    border-top-color: #6c62f6;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
  50% {
    width: 100%;
    height: 0;
    border-top-color: #6c62f6;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: #6c62f6;
  }
  100% {
    width: 100%;
    height: 100%;
    border-top-color: #6c62f6;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: #6c62f6;
  }
}

.meerInfo:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border: .2rem solid transparent;
}
.meerInfo:hover:after {
  animation: animate2 0.5s linear forwards;
}

@keyframes animate2 {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #6c62f6;
    border-right-color: transparent;
  }
  50% {
    width: 0;
    height: 100%;
    border-top-color: transparent;
    border-bottom-color: #6c62f6;
    border-left-color: #6c62f6;
    border-right-color: transparent;
  }
  100% {
    width: 100%;
    height: 100%;
    border-top-color: transparent;
    border-bottom-color: #6c62f6;
    border-left-color: #6c62f6;
    border-right-color: transparent;
  }
}

.bodySec {
  width: 90%;
  margin: 10rem auto 6rem;
}

.bodySecArt {
  
  display: flex;
  justify-content: space-between;
  margin: 14rem 0;
}

.bodySecArt:nth-child(even) {
  flex-direction: row-reverse;
  text-align: right;
}

.imgBody {
  width: 45vw;
  height: auto;
  padding: 1rem;
}

.artTitle {
  font-size: 4.4rem;
  margin: 2rem 0 3rem;
  color: black;
  font-weight: 300;
  text-transform: none;
}

.quoteText {
  color: #3b62fa;
  font-size: 3rem;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  line-height: 4rem;
  max-width: 67rem;
}

.quoteSec {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 9rem 0 1rem;
  background-color: #fafafa;
  padding: 8rem 0 8rem;
}

.serviceSp {
  background-color: #fafafa;
  width: 100%;
  padding: 4rem 0 6rem;
}

.serviceHeader {
  margin: 0 auto;
  width: 90%;
}

.homeTitleS {
  font-size: 4rem;
  margin: 3rem 0 1.6rem;
  color: black;
  text-transform: capitalize;
  font-weight: 300;
}

.serviceArt {
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.serviceDiv {
  width: 48rem;
  padding-right: 1.6rem;
}

.serviceSpan {
  color: black;
  font-weight: 600;
  font-style: regular;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.serviceSpan2 {
  text-decoration: none;
  color: black;
  font-weight: 600;
  font-style: regular;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.serviceSpan2:hover {
  color:  #6c62f6;
  transition: color .5s;
}

.homeBodyText2 {
  font-size: 1.6rem;
  margin: 2rem 0 1rem;
  color: black;
  line-height: 2.6rem;
}

.extraSection {
  width: 90%;
  margin: 0 auto;
  margin-top: -4rem;
  display: flex;
  justify-content: space-between;
}

.extraSecDiv {
  padding: 2rem 1.6rem;
  border: .1rem solid #c4c0ff;
  margin: 0 1rem;
  font-size: 1.6rem;
  line-height: 2.6rem;
  background: white;
  
}

cite {
  color: rgb(171, 171, 171);
  font-style: italic;
  font-size: 1.8rem;
  font-weight: 400;
  
}

.extraPar {
  margin-top: 1rem;
}

.highText {
  display: inline-block;
  line-height: 0em;
  padding-bottom: 0.5em;
  background: linear-gradient(180deg,#f2f1ff 0%, #b5c4ff 80%);
}

.phoneNumber {
  text-decoration: none;
  color: black;
  font-size: 1.8rem;
  font-weight: 500;
  display: inline-block;
  line-height: 0em;
  padding-bottom: 0.5em;
  
  background: linear-gradient(180deg,#f2f1ff 0%, #b5c4ff 80%);
}

.divForCites {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.myCiteImg {
  transform: scale(1.4);
  transform-origin: left;
}

@media only screen and (max-width: 1260px) {

  
  .extraSection {
    margin-top: -4rem;
    display: flex;
    flex-direction: column;
  }
  
  .extraSecDiv {
    padding: 2rem 1.6rem;
    margin: 1rem;
  }
}
  

@media only screen and (max-width: 960px) {

  .pageDivFoto {
    transform: scale(1.4);
    transform-origin: top left;
    top: -6rem;
    margin-bottom: 6rem;
    margin-top: 2rem;
    width: 50rem;
  }

  .secMain {
    flex-flow: column-reverse;
     margin-top: 0;
   }

  .bodySecArt {
    display: flex;
    flex-direction: column;
    margin: 10rem 0;
  }

  .bodySecArt:nth-child(even) {
    flex-direction: column;
    text-align: left;
  }

  .imgBody {
    width: 90vw;
    height: 20vw;
    padding: 0;
    margin-bottom: 2rem;
    object-fit: cover;
    object-position: 15% 15%; 
   
  }

  .pageDivText {
    padding: 0;
  }

  .pageDivText1 {
    padding-right: 0;
  }

  .serviceArt {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  
  .serviceDiv {
    width: 100%;
    padding-right: 1.6rem;
  }
}

@media only screen and (max-width: 428px) { 

  .bodySecArt {
    display: flex;
    flex-direction: column;
    margin: 8rem 0;
  }

  .quoteSec {
    padding: 6rem 0 8rem;
  }

  .homeTitle {
    font-size: 3.6rem;
    margin: 2rem 0 1rem;
    color: black;
    text-transform: capitalize;
    font-weight: 300;
  }

  .pageDivFoto {
    transform: scale(1.6);
    transform-origin: top left;
    top: -6rem;
    margin-bottom: 6rem;
    margin-top: 2rem;
    width: 20rem;
  }

  .pageDivFoto {
    margin: 2rem 0;
    animation: animPhoto 0.8s ease-in;

  }

  .quoteText {
    font-size: 2.6rem;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    line-height: 3.6rem;
    max-width: 36rem;
  }

  .imgBody {
    width: 90vw;
    height: 20vw;
    padding: 0;
    margin-bottom: 2rem;
    object-fit: cover;
    object-position: 15% 15%; 
   
  }
  
}



