.appHeader {
  min-height: 6vh;
}

.tabBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 8rem;
  padding: 0 7rem;
  color: #fff;
  position: fixed;
  background-color: rgb(255, 255, 255);
  opacity: .9;
  z-index: 1;
  
}
.tabBar svg {
  display: block;
  margin: 2rem 0;
 
}
.tabBar a {
  color: #000000;
  font-size: 1.4rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
}

.tabBar .active {
  color: #000000;
  font-weight: 600;
}
.tabBar .active svg {
  fill: rgb(0, 0, 0);
}

.appLogo {
  cursor: pointer;
  
}

.mainLogo{
  width: 20rem;
  height: auto;
}

.navDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 70rem;
  
}

.navDiv a {
  border-top: 0.4rem solid #ffffff;
  padding: 4rem 0 1rem;
  transition: border .5s;
  cursor: pointer;
}

.navDiv a:hover {
  color:  #6C62F6;
  border-top: 0.4rem solid #6C62F6;
  transition: all .5s;
}

.navDiv > .active {
  border-top: 0.4rem solid #000000;
  padding: 4rem 0 1rem;
}

.myHamburger {
  display: none;
}

.myUlHamb {
  outline: none;
  text-align: left;
  position: relative;
  top: .8rem;
  margin-left: 4rem;
}

.myUlHamb a {
  cursor: pointer;
  color: white;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 10rem;
}

.myUlHamb a:hover {
  color:  #6C62F6;
}

.closeBtn {
  cursor: pointer;
  font-size: 5rem;
  font-weight: 300;
  color: white;

}

.closeBtn:hover {
  color: #6C62F6;
}

@media only screen and (max-width: 1180px) {

.navDiv{
  width: 60rem;
}

.tabBar {
  padding: 0 5rem;
}

}

@media only screen and (max-width: 960px) {

  .myHamburger {
    margin-right: -2rem;
  }

  .mainLogo {
    width: 14rem;
  }

  .myHamburger {
    display: block;
    overflow: hidden;
    color:  #1010C2;
    font-size: 6rem;
    
  }

  .myHamburger {
    z-index: 100;
  }

  .openHamb {
    font-size: 4rem;
    color:  #1010C2;
    cursor: pointer;
  }

  .myMenuBg {
    background-color: #151525;
    position: absolute;
    top: 0;
    scroll-behavior: smooth;
    outline: none;
    z-index: 10000;
    position: absolute;
    bottom: 0;
    left: 16%;
    right: 0rem;
    overflow: hidden;
    overscroll-behavior: contain;
  }

  .navDiv {
    display: none;
  }

  .tabBar .active {
    color: #6C62F6;
  }
  
}

@media only screen and (max-width: 728px) {

  .mainLogo {
    width: 12rem;
  }

  .tabBar {
    height: 6rem;
    padding: 0 4rem;
  }

  .myHamburger {
    font-size: 4rem;
  }


  .myHamburger {
    margin-top: 1rem;
  }

}

@media only screen and (max-width: 428px) {

  .mainLogo {
    width: 12rem;
    
  }

  .tabBar {
    height: 6rem;
    padding-left: 2.2rem;
  }

  .myUlHamb a {
   
    font-size: 2rem;
    line-height: 8rem;
  }
}

@media only screen and (max-width: 360px) {

  .myUlHamb a {
    font-size: 2rem;
    line-height: 7rem;
  }
}


