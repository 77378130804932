footer {
  background-color:  #151525;
  padding: 4rem 0 0;
  color: rgb(250, 250, 250, .9);
}

.footerSec {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.smallSec {
  margin: 0 1rem;
}

.footerP {
  margin-bottom: .5rem;
  color: rgb(250, 250, 250, .8);
  font-size: 1.8rem;
  line-height: 3rem;
}

.footerP2 {
  margin-bottom: .5rem;
  color: rgb(250, 250, 250, .8);
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 400;

}

.footerLink {
  color: rgb(250, 250, 250, .8);
  transition: color .5s;
  text-decoration: none;
  font-weight: 400;
}

.footerLink:hover {
  color: #b5c4ff;
  transition: color .5s;
}

.openingsuren {
  font-size: 1.8rem;
  font-weight: regular;
}

.footerTitle {
  font-size: 2.4rem;
  text-transform: capitalize;
  font-weight: regular;
  margin-bottom: 1rem;
}

.footerVindOns {
  margin-top: 5rem;
}

.footerAnc {
  cursor: pointer;
}
.table.customTable {
  width: 100%;
}

table.customTable td,
table.customTable th {
  border: none;
  padding: 1rem 0;
  padding-right: 3rem;
  font-size: 1.6rem;
}

.footerSec2 {
    width: 100%;
    background-color: #E1E7FF;
   
  }

  .footerSectionWidth {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1.8rem 0;
      color: #494949;
      font-size: 1.4rem;
  }

  .mediaI {
      display: flex;
      justify-content: space-between;
      width: 8rem;

  }

  .face {
      width: 3rem;
      height: 3rem;
      opacity: .6;
  }
  .insta {
      width: 3.6rem;
      height: 3.6rem;
      opacity: .6;
      position: relative;
      bottom: .3rem;
  }
  .hidden {
    display: none;
  }

  .imgBody {
    padding: .3rem;
    border: .1rem solid #8981ff;
  }

  .imgBody:hover {
    opacity: .8;
    padding: .4rem;
    transition: opacity .5s;
  }

  .rightStyle {
    font-size: 1.8rem;
    color: black;
  }

  @media only screen and (max-width: 960px) {
   
    .footerSec {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem;
    }

    .smallSec {
      margin: 2rem 0;
    }

  }
