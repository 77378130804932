@import "./reset.css";
/* @import "./colors.module.css"; */

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.hide{
  display: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto,sans-serif;
  font-weight: 300;
}

main {
  background-color: white;
}

.mainHeader {
  background-color: white;
}

.container {
  width: 100%;
  margin: 0 auto 8rem;
  
}

.active {
  display: none;
}

.nonactive {
  font-style: regular;
}

.pressed {
  position: relative;
  text-decoration: none;
  padding: 0.6rem 1.2rem;
  margin-right: 0;
 
  font-size: 3rem;  
  display: inline-block;
  outline: none;
  /* box-shadow: 0px 1rem 2rem rgb(129, 149, 211, 0.1);
  background: rgb(255, 255, 255, 0.2); */
  color: #6c62f6;
  font-weight: 600;
  transition: color 1s;

}

.pressed:hover {
  background: rgb(255, 255, 255, 0.2);
  color: #6c62f6;
  font-weight: 600;
  transition: color 1s;

}

.pressed:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border: .2rem solid transparent;
}

.pressed:hover::before {
  animation: animate22 0.5s linear forwards;
}

.pressed:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border: .2rem solid transparent;
}

.pressed:hover::after {
  animation: animate44 0.5s linear forwards;
}

.nonpressed {
  position: relative;
  text-decoration: none;
  padding: 0.6rem 1.2rem;
 
  font-weight: 400;
  font-size: 3rem;
  color: white;
  display: inline-block;
  background: linear-gradient(45deg, #2062fd, #6c62f6);
  outline: none;
}

.nonpressed:hover {
  background: rgb(255, 255, 255, 0.2);
  color: #6c62f6;
  font-weight: 600;
  transition: color 1s;
}

.nonpressed::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border: .2rem solid transparent;
}

.nonpressed:hover::before {
  animation: animate22 0.5s linear forwards;
}

@keyframes animate22 {
  0% {
    width: 0;
    height: 0;
    border-top-color: #6c62f6;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
  50% {
    width: 100%;
    height: 0;
    border-top-color: #6c62f6;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: #6c62f6;
  }
  100% {
    width: 100%;
    height: 100%;
    border-top-color: #6c62f6;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: #6c62f6;
  }
}

.nonpressed:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border: .2rem solid transparent;
}
.nonpressed:hover:after {
  animation: animate44 0.5s linear forwards;
}

@keyframes animate44 {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #6c62f6;
    border-right-color: transparent;
  }
  50% {
    width: 0;
    height: 100%;
    border-top-color: transparent;
    border-bottom-color: #6c62f6;
    border-left-color: #6c62f6;
    border-right-color: transparent;
  }
  100% {
    width: 100%;
    height: 100%;
    border-top-color: transparent;
    border-bottom-color: #6c62f6;
    border-left-color: #6c62f6;
    border-right-color: transparent;
  }
}

.meerInfoSp {
  border: 0;
  padding: 0;
  background-color: transparent;
  font-family: inherit;
  cursor: pointer;
  margin-top: 2rem;
  outline: none;

}

.meerInfoSp:active :focus{
  outline: none;
  border: .2rem solid transparent;
}

.showTypo {
  font-style: regular;
  margin-left: .6rem;
  font-size: 1.74rem;
  
}

.hideTypo {
  display: none;
}

